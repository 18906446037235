import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './register.user';

 var $g_fns_data = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['id','name','email','userName','phone','avatarUrl','newPassword','confirmPassword','verifyCode','openId','more','_key']);
		}
}; 


 var $g_fns_ctrl = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['id','btnLabel','btnDisabled','useSmsService','_key']);
		}
}; 

import '../../commonOperation/commonOperation'; 
import '../../tableData/tableData'; 
import '../../icon/css/icon.css'; 
import '../../restData/restData'; 
import '../../input/input'; 
import '../../input/css/input.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../row/css/row.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../wrapper/wrapper'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../wxApi/wxApi'; 
var methods = {

 $refPathFn_input7: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refPathFn_input21: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refFn_input7: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.userName ;
}

,
 $refFn_input6: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.name ;
}

,
 $refFn_input3: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.confirmPassword ;
}

,
 $refFn_input2: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.newPassword ;
}

,
 $refFn_input21: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.verifyCode ;
}

,
 $refFn_input5: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.email ;
}

,
 $refFn_input1: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.phone ;
}

,
 $refPathFn_input6: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refPathFn_input5: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refPathFn_input3: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refPathFn_input2: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refPathFn_input1: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $attrBindFn_hidden_btnUserinfo: function({data,$row,ctrl,$comp,params,$page,props}){
 try{return wx.Util.iif(wx.Device.isMiniProgram()!=true,true,false)}catch(e){return ''} ;
}

}
var template = [
	[
		{
			"cls":wx.compClass('$UI/wxsys/comps/restData/restData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"id":{
								"define":"id",
								"label":"id",
								"type":"string",
								"extType":"String"
							},
							"name":{
								"define":"name",
								"label":"姓名",
								"type":"string",
								"extType":"String"
							},
							"email":{
								"define":"email",
								"label":"邮箱",
								"type":"string",
								"extType":"String"
							},
							"userName":{
								"define":"userName",
								"label":"登录名",
								"type":"string",
								"extType":"String"
							},
							"phone":{
								"define":"phone",
								"label":"手机",
								"type":"string",
								"extType":"String"
							},
							"avatarUrl":{
								"define":"avatarUrl",
								"label":"头像",
								"type":"string",
								"extType":"String"
							},
							"newPassword":{
								"isCal":true,
								"define":"EXPRESS",
								"label":"新密码",
								"type":"string"
							},
							"confirmPassword":{
								"isCal":true,
								"define":"EXPRESS",
								"label":"确认密码",
								"type":"string"
							},
							"verifyCode":{
								"isCal":true,
								"define":"EXPRESS",
								"label":"验证码",
								"type":"string"
							},
							"openId":{
								"isCal":true,
								"define":"EXPRESS",
								"label":"openId",
								"type":"string"
							},
							"more":{
								"isCal":true,
								"define":"EXPRESS",
								"label":"显示更多",
								"type":"boolean"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_data
					},
					"id":"data",
					"keyItems":"_key"
				},
				"options":{
					"calcTotal":"",
					"distinct":false,
					"className":"/uaa/user",
					"autoMode":"new",
					"checkRange":"",
					"enableCustomIdValue":false,
					"tableName":"user",
					"confirmRefreshText":"",
					"allowEmpty":false,
					"feature":{
						"filter":true,
						"subquery":true,
						"distinct":true,
						"paging":true,
						"sort":true,
						"join":true,
						"fields":true,
						"params":false
					},
					"confirmDeleteText":"",
					"confirmRefresh":false,
					"primaryColumns":[
						"id"
					],
					"isReference":false,
					"restResource":"class",
					"isMain":false,
					"directDelete":true,
					"serviceName":"uaa",
					"defSlaves":[],
					"confirmDelete":true,
					"enableContextReadonly":true,
					"checkMode":"",
					"isAllColumns":true,
					"multiplePrimary":false,
					"idColumn":"id"
				},
				"id":"data",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"id":{
								"define":"id",
								"label":"ID",
								"type":"string",
								"extType":"String"
							},
							"btnLabel":{
								"define":"btnLabel",
								"label":"获取验证码按钮文字",
								"type":"string",
								"extType":"String"
							},
							"btnDisabled":{
								"define":"btnDisabled",
								"label":"获取验证码按钮可用",
								"type":"boolean",
								"extType":"Boolean"
							},
							"useSmsService":{
								"define":"useSmsService",
								"label":"是否启用短信服务",
								"type":"boolean",
								"extType":"Boolean"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_ctrl
					},
					"id":"ctrl",
					"keyItems":"_key"
				},
				"initData":[
					{
						"useSmsService":true,
						"btnDisabled":false,
						"id":"data",
						"btnLabel":"获取验证码"
					}
				],
				"options":{
					"calcTotal":"",
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"enableCustomIdValue":false,
					"confirmDelete":true,
					"enableContextReadonly":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":false,
					"idColumn":"id"
				},
				"id":"ctrl",
				"filters":{}
			}
		}
	],
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"$events":{
				"load":"pageLoad",
				"unload":"pageUnload"
			},
			"id":"page"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"phone",
			"$refFn":"$refFn_input1",
			"id":"input1",
			"$refPathFn":"$refPathFn_input1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"verifyCode",
			"$refFn":"$refFn_input21",
			"id":"input21",
			"$refPathFn":"$refPathFn_input21"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"button2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"userName",
			"$refFn":"$refFn_input7",
			"id":"input7",
			"$refPathFn":"$refPathFn_input7"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"newPassword",
			"$refFn":"$refFn_input2",
			"id":"input2",
			"$refPathFn":"$refPathFn_input2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"confirmPassword",
			"$refFn":"$refFn_input3",
			"id":"input3",
			"$refPathFn":"$refPathFn_input3"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"more"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"name",
			"$refFn":"$refFn_input6",
			"id":"input6",
			"$refPathFn":"$refPathFn_input6"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"$events":{
				"getUserInfo":"setUserInfo"
			},
			"id":"btnUserinfo",
			"$attrBindFns":{
				"hidden":"$attrBindFn_hidden_btnUserinfo"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"email",
			"$refFn":"$refFn_input5",
			"id":"input5",
			"$refPathFn":"$refPathFn_input5"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"button1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"depends":["__toptips__.show","__toptips__.compid"],
			"__auto1":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			}
		},
		"form":{
			"cell4":{
				"bd4":{
					"depends":[],
					"input21":{
						"depends":["data.current._userdata.verifyCode.required.val","data.current._userdata","data.current._userdata.verifyCode","data.current._userdata.verifyCode.readonly","data.current.verifyCode","input21.compid","__pageid__","data.current._userdata.verifyCode.hidden","data.current"]
					}
				},
				"depends":["ctrl.current.useSmsService"],
				"ft4":{
					"button2":{
						"label_vcode-btn":{
							"depends":["ctrl.current.btnLabel"]
						},
						"depends":["button2.compid","__pageid__","ctrl.current.btnDisabled"]
					},
					"depends":[]
				},
				"hd4":{
					"label4":{
						"label4_1":{
							"depends":[]
						},
						"depends":[]
					},
					"depends":[]
				}
			},
			"morelink":{
				"depends":[],
				"more":{
					"depends":["more.compid","__pageid__"],
					"label_more":{
						"depends":[]
					}
				}
			},
			"cell5":{
				"depends":["data.current.more"],
				"hd5":{
					"label5":{
						"label5_1":{
							"depends":[]
						},
						"depends":[]
					},
					"depends":[]
				},
				"bd54":{
					"input5":{
						"depends":["data.current._userdata.email.required.val","data.current._userdata","data.current._userdata.email","data.current._userdata.email.readonly","data.current.email","input5.compid","__pageid__","data.current._userdata.email.hidden","data.current"]
					},
					"depends":[]
				}
			},
			"cell2":{
				"bd2":{
					"depends":[],
					"input2":{
						"depends":["data.current._userdata.newPassword.required.val","data.current._userdata","data.current._userdata.newPassword","data.current._userdata.newPassword.readonly","true","data.current.newPassword","input2.compid","__pageid__","data.current._userdata.newPassword.hidden","data.current"]
					}
				},
				"depends":[],
				"hd21":{
					"depends":[],
					"label2":{
						"depends":[],
						"label2_1":{
							"depends":[]
						}
					}
				}
			},
			"cell3":{
				"bd3":{
					"depends":[],
					"input3":{
						"depends":["data.current._userdata.confirmPassword.required.val","data.current._userdata","data.current._userdata.confirmPassword","data.current._userdata.confirmPassword.readonly","true","data.current.confirmPassword","input3.compid","__pageid__","data.current._userdata.confirmPassword.hidden","data.current"]
					}
				},
				"depends":[],
				"hd3":{
					"depends":[],
					"label3":{
						"depends":[],
						"label3_1":{
							"depends":[]
						}
					}
				}
			},
			"depends":[],
			"cell6":{
				"bd6":{
					"input6":{
						"depends":["data.current._userdata.name.required.val","data.current._userdata","data.current._userdata.name","data.current._userdata.name.readonly","data.current.name","input6.compid","__pageid__","data.current._userdata.name.hidden","data.current"]
					},
					"depends":[]
				},
				"depends":["data.current.more"],
				"ft5":{
					"depends":[],
					"btnUserinfo":{
						"btnUserinfo-icon":{
							"depends":[]
						},
						"depends":["btnUserinfo.debounce","btnUserinfo.compid","__pageid__","btnUserinfo.hidden"],
						"btnUserinfo-view":{
							"depends":[]
						}
					}
				},
				"hd6":{
					"label6":{
						"label6_1":{
							"depends":[]
						},
						"depends":[]
					},
					"depends":[]
				}
			},
			"cell7":{
				"bd7":{
					"input7":{
						"depends":["data.current._userdata.userName.required.val","data.current._userdata","data.current._userdata.userName","data.current._userdata.userName.readonly","ctrl.current.useSmsService","data.current.userName","input7.compid","__pageid__","data.current._userdata.userName.hidden","data.current"]
					},
					"depends":[]
				},
				"depends":[],
				"hd7":{
					"label7":{
						"label7_1":{
							"depends":[]
						},
						"depends":[]
					},
					"depends":[]
				}
			},
			"cell1":{
				"hd1":{
					"depends":[],
					"label1":{
						"depends":[],
						"label1_1":{
							"depends":[]
						}
					}
				},
				"depends":["ctrl.current.useSmsService"],
				"bd1":{
					"depends":[],
					"input1":{
						"depends":["data.current._userdata.phone.required.val","data.current._userdata","data.current._userdata.phone","data.current._userdata.phone.readonly","data.current.phone","input1.compid","__pageid__","data.current._userdata.phone.hidden","data.current"]
					}
				}
			}
		},
		"inVisibleCompContainer1":{
			"depends":[]
		},
		"depends":["page.compid","__pageid__"],
		"row7":{
			"depends":[],
			"col21":{
				"depends":[]
			},
			"col20":{
				"depends":[],
				"button1":{
					"depends":["button1.debounce","button1.compid","__pageid__"],
					"button1-icon":{
						"depends":[]
					},
					"button1-label":{
						"depends":[]
					}
				}
			},
			"col19":{
				"depends":[]
			}
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {"navigationBarTitleText":"注册"},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
